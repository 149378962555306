@import "../../../styles/utils/mixins";
@import "../../../styles/utils/variables";

.root {
  background: rgba(31, 39, 50, 0.8);
  padding-top: 24px;
  padding-bottom: 24px;
}

.container {
  background: #F1F1F1;
  border-radius: 8px;
  display: flex;
  flex-direction: column;

  @include onLaptop {
    flex-direction: row;
  }
}

.banner {
  width: 100%;
  order: 2;
  @include onLaptop {
    width: 33.33%;
    order: 1;
  }
}

.info {
  margin-top: 12%;
  width: 100%;
  order: 1;
  @include onLaptop {
    width: 66.66%;
    order: 2;
  }
}

.sectionTitleBar {
  border-radius: 1px;
  background-color: #a28977;
  width: 118px;
  height: 4px;
}

.info h1 {
  font-family: var(--font-cormorant);
  font-style: normal;
  font-weight: 400;
  font-size: 65px;
  line-height: 136px;
  /* identical to box height */

  letter-spacing: -0.03em;
  text-transform: uppercase;

  /* Text/Text Dark */

  color: #151A23;

  @include onLaptop {
    font-weight: 400;
    font-size: 112px;
  }
}

.info p {
  padding-bottom: 50px;

  font-family: 'Mulish';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 158%;
  /* or 38px */

  letter-spacing: -0.03em;

  /* Text/Text Dark */

  color: #151A23;
}

.highlight {
  font-family: 'Cormorant';
  font-weight: 600;
  font-style: italic;
  font-size: 40px;
  color: #A28977;
}

.desktopImage {
  display: none;

  @include onLaptop {
    width: 212px;
    height: 771px;
    object-fit: cover;
    margin: 60px 80px 60px;
    display: block;
  }
}