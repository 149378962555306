@import "../../styles/utils/mixins";
@import "../../styles/utils/variables";

.root {
  background-color: rgba(27, 29, 30, 0.7);
  backdrop-filter: blur(24px);
  border-bottom: 1px solid rgba(151, 151, 151, 0.1);
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 4;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 16px;
  @include onLaptop {
    // padding-top: 20px;
    // padding-bottom: 20px;
  }
}


.logo {
  display: block;

  img {
    display: block;
    height: 60px;

    @include onLaptop {
      width: 293px;
      height: 84.73px;
    }
  }
}

.container > .logo {
  @include onLaptop {
    display: none;
  }
}


.nav {
  display: none;
  flex-direction: row;
  top: 0;
  left: 0;
  position: relative;
  width: auto;
  height: auto;
  background-color: $secondary;
  margin-top: 64px;
  overflow-y: auto;

  @include onLaptop {
    display: flex;
    background-color: initial;
    margin-top: 0;
    gap: 24px;
  }
}

.navCompact {
  display: none;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  //height: calc(100% - 64px);
  background-color: $primary;
  margin-top: 64px;
  overflow-y: auto;

  &.navIsOpen {
    display: flex;

    @include onLaptop {
      display: none;
    }
  }

  @include onLaptop {
    display: none;
  }
}


.navItem {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  border-top: 1px solid rgba(white, 0.23);

  color: #FFFFFF;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  /* identical to box height */

  text-transform: uppercase;

  padding: 16px 24px;

  @include onLaptop {
    border-top: none;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.1px;

    padding: 0;

    &:hover {
      cursor: pointer;
      color: $secondary;

      &::after {
        opacity: 1;
      }
    }
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: $secondary;
    opacity: 0;
  }
}

.navItemActive {
  color: $secondary;

  &::after {
    opacity: 1;
  }
}

.buttonIcon {
  @include onLaptop {
    display: none;
  }
}
