@import "../../../styles/utils/mixins";
@import "../../../styles/utils/variables";

.root {
  background: rgba(31, 39, 50, 0.8);
  //padding-bottom: 24px;
  //@include onLaptop {
  //  padding-bottom: 96px;
  //}
}

.container {
  max-width: 1440px;
  padding: 40px 0 20px 0;
  background: #F1F1F1;

  @include onLaptop{
    padding-bottom: 80px;
  }
}

.sectionTitleWrapper {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
  max-width: 1440px;

  //margin: 0 auto 10% auto;
  //width: 520px;
}

.horizontalBarLarge {
  border-radius: 1px;
  background-color: var(--color-rosybrown);
  width: 118px;
  height: 4px;
  margin-right: auto;
  margin-left: auto;
}

.list {
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  letter-spacing: -0.03em;
  font-weight: 500;
}

.itemWrapper {
  background-color: var(--color-whitesmoke-100);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.04), -4px 0 8px rgba(0, 0, 0, 0.06);
  border: 1px solid var(--color-dimgray-200);
  box-sizing: border-box;

  position: relative;
  text-align: center;
  margin: 0 10px 60px 10px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 184px;
  height: 109px;
  border-radius: 39px;

  @include onTablet{
    border-radius: 56px;
    width: 246px;
    height: 156px;
  }
}

.itemIcon {
  position: absolute;
  top: -30px;
  left: calc(50% - 30px);
  width: 61px;
  height: 61px;

  @include onTablet{
    width: 88px;
    height: 88px;
    left: calc(50% - 40px);
    top: -40px;
  }
}