@font-face {
  font-family: 'MouseOver';
  src:  url('../../fonts/icons/mouseover-icons.eot?yicx9');
  src:  url('../../fonts/icons//mouseover-icons.eot?yicx9#iefix') format('embedded-opentype'),
  url('../../fonts/icons//mouseover-icons.ttf?yicx9') format('truetype'),
  url('../../fonts/icons//mouseover-icons.woff?yicx9') format('woff'),
  url('../../fonts/icons//mouseover-icons.svg?yicx9#mouseover-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'MouseOver' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-Close:before { content: "\e917"; }
.icon-MenuFilled:before { content: "\e918"; }
