// Base: Root styles
@import '../utils/variables';
@import '../utils/mixins';

*, *::before, *::after {
  box-sizing: border-box;
}

html {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 400;
}

body {
  background-image: url('../../images/body-bg-1.png');
  background-position: top center;
  background-repeat: repeat-y;
  background-size: percentage(1.5);

  @include onLaptop {
    //background-size: 75% auto;
    background-size: contain;
  }
}

.page {
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  touch-action: none;

  @include onLaptop {
    overflow: auto;
  }
}

#root {
  overflow: hidden;
}

h1, h2, h3, h4, h5, h6, p {
  margin-top: 0;
}

a {
  text-decoration: none;
}

button {
  background: none;
  border: none;
  outline: 0;
  font-family: 'Poppins', serif;

  &:hover {
    cursor: pointer;
  }
}

input {
  font-family: 'Poppins', serif;
}

.sectionTitle {
  letter-spacing: -0.03em;
  text-transform: uppercase;
  font-family: var(--font-cormorant);
  font-size: 60px;

  @include onTablet {
    font-size: 100px;
  }
  @include onLaptop {
    font-size: 112px;
  }
}

:root {
  /* fonts */
  --font-mulish: Mulish;
  --font-cormorant: Cormorant;
  --font-marck-script: "Marck Script";

  --font-size-base: 16px;
  --font-size-93xl: 112px;
  --font-size-29xl: 48px;
  --font-size-5xl: 24px;

  /* border radius */
  --br-base: 16px;
  --br-5xs: 8px;
  --br-12xs: 1px;
  --br-81xl: 100px;
  --br-xs: 12px;
  --br-9xs: 4px;
  --br-37xl: 56px;

  /* Colors */
  --color-gray-100: #1f2732;
  --color-gray-200: #151b23;
  --color-gray-300: #151a23;
  --color-gray-400: rgba(255, 255, 255, 0.02);
  --color-darkslategray-100: #3d4552;
  --main-dark: #2a455c;
  --color-white: #fff;
  --main-accent: #3c6ed0;
  --color-tan-100: #dbbca5;
  --color-tan-200: #d7ba9e;
  --color-tan-300: #c0a386;
  --color-tan-400: #bea183;
  --color-whitesmoke-100: #f1f1f1;
  --color-rosybrown: #a28977;
  --color-dimgray-100: #666;
  --color-dimgray-200: #5b514d;
  --color-black: #000;
  --color-red: rgba(251, 23, 14, 0.12);
  --color-gainsboro: #ccdae2;

  --color-section-title-bar: #a28977;
}