@import "../../../styles/utils/mixins";
@import "../../../styles/utils/variables";

.root {
  background: rgba(31, 39, 50, 0.8);
  color: var(--color-white);
}

.sectionTitleWrapper {
  padding: 20px 0 50px 5px;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  background-color: rgba(31, 39, 50);
}

.sectionTitleBar {
  border-radius: 1px;
  background-color: var(--color-rosybrown);
  width: 118px;
  height: 4px;
}

.container {
  position: relative;
  max-width: 1440px;
  padding-left: 0;
  padding-right: 0;
}

.backgroundImage {
  background-size: cover;
  background: url("../../../images/body-bg-2.png") no-repeat;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.backgroundDark {
  background-size: cover;
  background: url("../../../images/rectangle2.svg") repeat-y;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.content {
  border-radius: 8px;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr;
  grid-row-gap: 50px;
  grid-column-gap: 50px;
  padding: 50px 100px 50px 100px;

  @include onLaptop {
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 100px;
    grid-column-gap: 100px;
  }

  // my
  background-color: rgba(241, 241, 241, 0.19);
  -webkit-backdrop-filter: blur(18px);
  backdrop-filter: blur(18px);
}

.stageIcon {
  margin-bottom: 10px;
}

.stageTitle {
  font-size: 20px;
  display: flex;
  margin-bottom: 10px;
}

.stageDesc {
  font-size: var(--font-size-base);
  line-height: 158%;
  font-weight: 500;
  color: #f1f1f1;
  max-width: 289px;
  opacity: 0.6;
  margin-bottom: 10px;
}

.stageDescList {
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
}

.stageDescListItem {
  letter-spacing: -0.03em;
  line-height: 158%;
  font-weight: 500;
  width: 205px;
  margin-right: 50px;

  flex: 1 1 150px;
}

.horizontalBarSmall {
  border-radius: 1px;
  background-color: #a28977;
  width: 32px;
  height: 2px;
  margin-bottom: 5px;
}

.box2 {
  @include onLaptop {
    display: inline-grid;
    grid-column-end: span 2;
  }
}