@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=block");
@import url("https://fonts.googleapis.com/css2?family=Cormorant:ital,wght@0,400;0,600;0,700;1,500;1,600&display=block");
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;600;700&display=block");
@import url("https://fonts.googleapis.com/css2?family=Marck+Script:wght@400&display=block");

@import "./styles/base/normalize";
@import "./styles/base/root";
@import "./styles/base/icons";

@import "./styles/helper";

@import "./styles/utils/variables";
@import "./styles/utils/mixins";

@import "./styles/components/container";
@import "./styles/components/controls";
@import "./styles/components/buttons";
@import "./styles/components/typography";
@import "./styles/components/loader";
