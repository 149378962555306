@import "../../../styles/utils/mixins";
@import "../../../styles/utils/variables";

.root {
  background: rgba(31, 39, 50, 0.8);
  padding-top: 0;
}

.container {
  position: relative;
  max-width: 1440px;
  padding-left: 0;
  padding-right: 0;
}

.backgroundDark {
  background-size: cover;
  background: url("../../../images/rectangle3.svg") repeat-y;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.content {
  padding: 20px 10px 50px 10px;
}

.sectionTitleWrapper {
  text-align: right;
  color: var(--color-white);
}

.sectionTitleBar {
  border-radius: 1px;
  background-color: var(--color-section-title-bar);
  width: 118px;
  height: 4px;
  margin-left: auto;
  margin-right: 0;
}

.teaser {
  color: var(--color-white);
  font-weight: 300;
  font-family: var(--font-cormorant);
  font-size: 10px;
  margin-bottom: 20px;

  @include onLaptop {
    font-size: 24px;
    margin-top: -25px;
  }
  @include onTablet {
    font-size: 24px;
    margin-top: -25px;
  }
}

.priceContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.priceContainer > div {
  border-radius: 12px 12px 4px 4px;
  background-color: var(--color-white);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.04), 4px -4px 8px rgba(0, 0, 0, 0.06);
  width: 325px;
  height: 550px;
  margin-right: 10px;
  margin-left: 10px;
  text-align: center;
  font-size: 14px;
  color: var(--color-black);
  font-family: var(--font-cormorant);
  margin-bottom: 10px;
  position: relative;

  @include onLaptop{
    height: 600px;
  }
  @include onTablet{
    height: 600px;
  }
}

.title {
  border-radius: 12px 12px 0 0;
  background-color: var(--color-gray-300);
  height: 104px;

  color: var(--color-white);
  letter-spacing: -0.03em;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 24px;

  display: flex;
  align-items: center;
  justify-content:center;
}

.price {
  height: 50px;

  letter-spacing: -0.03em;
  font-weight: 600;
  color: var(--color-tan-300);
  font-size: 24px;
}

.priceValue {
  font-size: var(--font-size-29xl);
}

.servicesContainer {
  padding: 20px 20px 20px 20px;
  font-family: var(--font-mulish);
  text-align: left;
}

.servicesContainer > div {
  display: flex;
  margin: 20px;
}

.servicesContainer > div :nth-child(1) {
  margin-right: 6px;
}

.info {
  font-size: 11px;
  color: var(--color-dimgray-100);
}

.bottomBar {
  background-color: var(--color-tan-400);
  width: 85px;
  height: 1px;
  position: absolute;
  bottom: 10px;
  transform: translate(150%);
}