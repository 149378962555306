@import "../../../styles/utils/mixins";
@import "../../../styles/utils/variables";

.root {
  background: rgba(31, 39, 50, 0.8);
}

.container {
  position: relative;
  max-width: 1440px;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 10px;

  @include onLaptop {
    height: calc(40vw + 180px);
    padding-bottom: 0;
  }
  @include onTablet {
    height: calc(40vw + 180px);
    padding-bottom: 0;
  }
}


.sectionTitle {
  text-align: center;
  color: var(--color-whitesmoke-100);
}

.sectionTitleBar {
  border-radius: var(--br-12xs);
  background-color: var(--color-rosybrown);
  width: 118px;
  height: 4px;
  margin: 0 auto;
}

// large screen

.contentWrapper.laptop {
  position: absolute;
  display: block;
  width: 100%;
  height: 70%;
  bottom: 0;
}

.horizontalContent {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  height: 100%;

  display: flex;
  flex-wrap: nowrap;
  align-items: flex-end;
  justify-content: center;
  padding-left: 5px;
  padding-right: 5px;

  z-index: 2;
}

.horizontalContent > *:nth-child(1) {
  height: 100%;
  align-self: stretch;
  transform: translateX(40%);
  flex-shrink: 5;
}

.horizontalContent > *:nth-child(2) {
  width: auto;
  height: 100%;
  margin-right: -5%;
}

.horizontalContent > *:nth-child(3) {
  width: auto;
  height: 100%;
  margin-left: -5%;
  z-index: 1;
  flex-shrink: 5;
}

.horizontalContent > *:nth-child(4) {
  height: 100%;
  //margin-top: 10%;
  align-self: stretch;
  transform: translateX(-30%);
}

.contentWrapper.laptop {
  visibility: hidden;

  @include onLaptop {
    visibility: visible;
  }
  @include onTablet {
    visibility: visible;
  }
}

.backgroundDark {
  background-size: cover;
  background: url("../../../images/rectangle1.svg") repeat-y;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.backgroundAureole {
  background: url("../../../images/ellipse-53.svg") no-repeat top center;

  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;

  visibility: hidden;

  @include onLaptop{
    visibility: visible;
  }
  @include onTablet{
    visibility: visible;
  }
}

.backgroundWhite {
  position: absolute;
  bottom: 0;
  height: 50px;
  background-color: white;
  background-size: cover;
  width: 100%;
  z-index: 1;
  visibility: hidden;

  @include onLaptop {
    visibility: visible;
  }

  @include onTablet {
    visibility: visible;
  }
}

.container img {
  overflow-clip-margin: content-box;
  overflow: clip;
}


.firstName {
  text-align: center;
  font-size: 32px;
  color: var(--color-tan-200);
  font-family: var(--font-marck-script);

  @include onLaptop {
    font-size: 64px;
  }
}

.lastName {
  text-align: center;
  //font-size: var(--font-size-29xl);
  font-size: 32px;
  font-weight: 600;
  color: var(--color-tan-200);
  font-family: var(--font-cormorant);
  margin-top: -20px;

  @include onLaptop {
    font-size: var(--font-size-29xl);
  }
}

.jobTitle {
  text-align: center;
  font-size: var(--font-size-5xl);
  letter-spacing: 0.47em;
  font-weight: 300;
  font-family: var(--font-mulish);
  color: var(--color-white);
}

.btn {
  width: 74.79%;
  top: 188px;
  right: 12.75%;
  left: 12.46%;
  height: 68px;
  text-align: center;
  font-size: var(--font-size-sm);
  color: var(--color-gray-100);
  font-family: var(--font-mulish);

  background-color: var(--color-tan-100);
  margin: 20px auto 0;

  display: flex;
  justify-content: center;
  align-items: center;
}

.btn a {
  vertical-align: middle;
  text-decoration: none;
  color: var(--color-gray-100);
}

// mobile

.contentWrapper.mobile {
  visibility: visible;

  @include onLaptop {
    visibility: hidden;
  }
  @include onTablet {
    visibility: hidden;
  }
}

.contentWrapper.mobile .photoWrapper img {
  height: 294px; // hard-coded to the lowest image height
  max-width: 100%;
  border-bottom: 2px solid #a28977;
}

.contentWrapper.mobile .row {
  display: grid;
  grid-template-columns: 2fr 1fr;
  padding-left: 5px;
}

.contentWrapper.mobile .row > div:nth-child(2) {
  margin-top: 50px;
}

.contentWrapper.mobile .row:first-child {
  margin-top: 20px;
}
