@import "../../../styles/utils/mixins";
@import "../../../styles/utils/variables";


.root {
  background: rgba(31, 39, 50, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  
  position: relative;

  //padding-top: 72px;
  //padding-bottom: 120px;

  min-height: 100vh;

  @include onLaptop {
    //min-height: 100vh;
    padding-top: 120px;
    padding-bottom: 1px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  @include onLaptop {
    flex-direction: row;
  }
}

.wrapper {
  order: 2;
  @include onLaptop {
    padding-right: 64px;
    order: initial;
  }
}

.companyTypeWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}

.companyTypeBar {
  border-radius: 1px;
  background-color: var(--color-tan-100);
  height: 4px;
  display: none;

  @include onLaptop {
    display: block;
    width: 117px;
    margin: auto 20px auto -127px;
  }
}

.companyType {
  font-size: 22px;
  text-transform: uppercase;
  color: #CCDAE2;

  @include onLaptop {
    font-size: 48px;
  }
  @include onTablet {
    font-size: 48px;
  }
}

.companyTitle {
  font-size: 60px;
  line-height: 100%;
  display: inline-block;
  font-family: var(--font-cormorant);
  color: $secondary;
  font-weight: bold;
  margin-bottom: 20px;
  word-wrap: break-word;

  @include onTablet {
    font-size: 132px;
  }
  @include onLaptop {
    font-size: 152px;
  }
}

.moto {
  letter-spacing: 0.24em;
  text-transform: uppercase;
  color: #CCDAE2;
  font-family: var(--font-mulish);
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 70px;

  @include onLaptop {
    font-size: 24px;
  }
}

.phoneGroup {
  display: flex;
  flex-wrap: wrap;

  max-width: 353px;
  height: 115px;

  border: 1px solid #FFFFFF;
  filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.02)) drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.08));
  border-radius: 8px 8px 8px 8px;

  @include onTablet {
    max-width: 100%;
    margin-bottom: 0;
    flex-wrap: nowrap;

    width: 853px;
    height: 115px;
  }
}


.phoneBox {
  width: 400px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  @include onTablet {
    width: 438px;
  }
}

.phone1 {
  background: $secondary;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04), 8px 8px 16px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  outline: 1px solid $secondary;

  color: #1F2732;
}

.phone2 {
  background: rgba(31, 39, 50, 0.8);
  color: #F1F1F1;
}