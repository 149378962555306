@import "../../styles/utils/mixins";
@import "../../styles/utils/variables";

.root {
  background: rgba(31, 39, 50, 0.8);
  padding-top: 5px;
}

.container {
  max-width: 1440px;
  padding-top: 50px;
  background-color: #1f2732;
  color: var(--color-white);
}

.title {
  font-size: 60px;
  font-weight: 300;
  font-family: var(--font-cormorant);
  margin-bottom: 30px;

  @include onLaptop {
    font-size: 70px;
  }
}

.content {
  display: flex;
  justify-content: space-between;
  margin-right: 50px;
  margin-left: 10px;
  font-size: 18px;
  font-weight: 300;
  line-height: 20px;
}

.content > div > div {
  margin-top: 20px;
}

.content > div > div > img {
  margin-right: 10px;
}

.socialItem {
  display: block;
  color: white;

  &:hover {
    color: $primary;
  }
}

.separator {
  background-color: #f0ece8;
  height: 1px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.social {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
}